import './style.css';

function Technology() {
    return (
        <div className="Technology">
            <h1>Title EARTH</h1>
            <h2>Title</h2>
            <h3>Title</h3>
            <h4>Title</h4>
            <h5>Title</h5>
        </div>
    );
}

export default Technology;
