import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Crew from './pages/Crew';
import Technology from './pages/Technology';
import Destination from './pages/Destination';
import NavBar from './component/NavBar';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <NavBar />
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route path="/crew">
                    <Crew />
                </Route>
                <Route path="/technology">
                    <Technology />
                </Route>
                <Route path="/destination">
                    <Destination />
                </Route>
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
