import './style.css';

function Crew() {
    document.body.dataset.bg = 'crew';
    return (
        <div className="Crew">
            <h1>Crew</h1>
            <h2>Title</h2>
            <h3>Title</h3>
            <h4>Title</h4>
            <h5>Title</h5>
        </div>
    );
}

export default Crew;
