import logo from '../../asset/shared/logo.svg';
import hamburger from '../../asset/shared/icon-hamburger.svg';
import close from '../../asset/shared/icon-close.svg';

import './style.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function NavBar() {
    let [showMenu, setShowMenu] = useState(false);
    function handleClick() {
        setShowMenu((state) => !state);
    }
    return (
        <nav className="NavBar" data-show-menu={showMenu}>
            <img src={logo} className="NavBar_logo" alt="logo" />
            <img
                src={hamburger}
                className="NavBar_hamburger"
                alt="hamburger"
                onClick={handleClick}
            />
            <div className="NavBar_submenu">
                <img
                    src={close}
                    className="NavBar_close"
                    alt="close button"
                    onClick={handleClick}
                />
                <div className="NavBar_submenu_link" onClick={handleClick}>
                    <Link to="/">
                        <span className="NavBar_submenu_link_number">00</span>{' '}
                        Home
                    </Link>
                    <Link to="/destination">
                        <span className="NavBar_submenu_link_number">01</span>{' '}
                        Destination
                    </Link>
                    <Link to="/crew">
                        <span className="NavBar_submenu_link_number">02</span>{' '}
                        Crew
                    </Link>
                    <Link to="/technology">
                        <span className="NavBar_submenu_link_number">03</span>{' '}
                        Technology
                    </Link>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
